import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { WidgetComponents, WidgetElements } from '../../../../utils/bi/consts';
import { TriggeredByOptions } from '../../../../types/types';
import {
  bookingsCalendarClick,
  bookingsCalendarBookingDetailsLoad,
} from '@wix/bi-logger-wixboost-ugc/v2';
import { PreferencesErrors } from '../../../../utils/bookingPreferences/bookingPreferences';
import {
  isServiceVariantWithStaff,
  isServiceVariantWithCustom,
} from '../../../../utils/dynamicPricing/dynamicPricing';

export type OnClearSelectedBookingPreferences = () => Promise<void>;

export function createOnClearSelectedBookingPreferencesAction({
  getControllerState,
  context,
}: ActionFactoryParams<
  CalendarState,
  CalendarContext
>): OnClearSelectedBookingPreferences {
  return async () => {
    const [state, setState] = getControllerState();
    const { biLogger } = context;
    const {
      selectedTime,
      calendarErrors,
      selectableSlotsAtSelectedTime,
      serviceVariantsMap,
      servicesInView,
    } = state;

    const service = servicesInView[0];
    const serviceVariants = serviceVariantsMap[service.id!];

    const calendarErrorsWithoutPreferencesErrors = calendarErrors.filter(
      (calendarError) => {
        return !PreferencesErrors.includes(calendarError);
      },
    );

    setState({
      selectedBookingPreferences: [],
      calendarErrors: calendarErrorsWithoutPreferencesErrors,
    });

    if (
      isServiceVariantWithStaff(serviceVariants) &&
      selectableSlotsAtSelectedTime?.length
    ) {
      const firstSlotStaffId =
        selectableSlotsAtSelectedTime[0].slot!.resource!.id!;
      if (
        selectableSlotsAtSelectedTime.some(
          ({ slot }) => firstSlotStaffId !== slot!.resource!.id,
        )
      ) {
        setState({
          selectedVariantsOptions: [],
        });
      }
    } else if (isServiceVariantWithCustom(serviceVariants)) {
      setState({
        selectedVariantsOptions: [],
      });
    }

    void biLogger.report(
      bookingsCalendarClick({
        component: WidgetComponents.BOOKING_DETAILS,
        element: WidgetElements.CLEAR_BUTTON,
      }),
    );

    void biLogger.report(
      bookingsCalendarBookingDetailsLoad({
        triggeredBy: TriggeredByOptions.BOOKING_DETAILS_CLEAR_BUTTON,
        selectedSlot: selectedTime,
      }),
    );
  };
}
</void>