import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { WidgetElements } from '../../../../utils/bi/consts';
import { bookingsUouFlowCalendarElementLoaded } from '@wix/bi-logger-wixboost-ugc/v2';

export type OnElementLoaded = (
  element: WidgetElements,
  rawProperties?: any,
) => void;

export const createOnElementLoaded =
  ({
    context: { biLogger },
  }: ActionFactoryParams<calendarstate, CalendarContext="">): OnElementLoaded =>
  (element, rawProperties) => {
    let value;

    if (rawProperties) {
      if (typeof rawProperties === 'object') {
        value = JSON.stringify(rawProperties);
      } else {
        value = rawProperties.toString();
      }
    }

    biLogger.report(
      bookingsUouFlowCalendarElementLoaded({
        element,
        value,
      }),
    );
  };
</calendarstate,>