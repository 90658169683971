import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { WidgetComponents, WidgetElements } from '../../../../utils/bi/consts';
import { SetSelectedDate } from '../setSelectedDate/setSelectedDate';
import { SetSelectedRange } from '../setSelectedRange/setSelectedRange';
import { SetSelectedMonth } from '../setSelectedMonth/setSelectedMonth';
import { AddError } from '../addError/addError';
import { getLocalDateTimeRangeForDay } from '../../../../utils/getLocalDateTimeRangeForDay/getLocalDateTimeRangeForDay';
import {
  bookingsCalendarClick,
  bookingsCalendarErrorMessages,
} from '@wix/bi-logger-wixboost-ugc/v2';
import {
  getEndOfMonthAsLocalDateTime,
  isMonthDifferent,
  isWeekDifferent,
} from '../../../../utils/dateAndTime/dateAndTime';
import {
  CalendarErrors,
  SlotsStatus,
  TriggeredByOptions,
} from '../../../../types/types';
import {
  isDailyTimeSlotsWeeklyPickerLayout,
  isWeeklyTimeSlotsLayout,
} from '../../../../utils/layouts';
import { AutoSelectTime } from '../autoSelectTime/autoSelectTime';

export type GoToNextAvailableDate = () => void;

export function createGoToNextAvailableDateAction(
  {
    getControllerState,
    context,
  }: ActionFactoryParams<calendarstate, CalendarContext="">,
  setSelectedDate: SetSelectedDate,
  setSelectedMonth: SetSelectedMonth,
  setSelectedRange: SetSelectedRange,
  addError: AddError,
  autoSelectTime: AutoSelectTime,
): GoToNextAvailableDate {
  return async () => {
    const [state, setState] = getControllerState();
    const { biLogger, calendarApi, settings, settingsParams, businessInfo } =
      context;
    const { selectedDate } = state;

    setState({
      slotsStatus: SlotsStatus.LOADING,
    });

    const sixMonthsFromSelectedDate = getEndOfMonthAsLocalDateTime(
      selectedDate!,
      7,
    );
    const nextAvailableLocalDateTime = await calendarApi.getNextAvailableDate(
      {
        fromAsLocalDateTime: selectedDate!,
        toAsLocalDateTime: sixMonthsFromSelectedDate,
      },
      { state, context, onError: addError },
    );

    if (nextAvailableLocalDateTime) {
      if (isWeeklyTimeSlotsLayout(settings, settingsParams)) {
        const range = getLocalDateTimeRangeForDay(
          businessInfo!.dateRegionalSettingsLocale!,
          nextAvailableLocalDateTime,
        );
        await setSelectedRange(
          range,
          TriggeredByOptions.GO_TO_NEXT_AVAILABLE_DATE_LINK,
        );
      } else if (isDailyTimeSlotsWeeklyPickerLayout(settings, settingsParams)) {
        const shouldFetchDateAvailability = isWeekDifferent(
          selectedDate!,
          nextAvailableLocalDateTime,
          businessInfo!.dateRegionalSettingsLocale!,
        );

        const range = getLocalDateTimeRangeForDay(
          businessInfo!.dateRegionalSettingsLocale!,
          nextAvailableLocalDateTime,
        );

        await Promise.all([
          setSelectedDate(
            nextAvailableLocalDateTime,
            TriggeredByOptions.GO_TO_NEXT_AVAILABLE_DATE_LINK,
          ),
          shouldFetchDateAvailability
            ? setSelectedRange(
                range,
                TriggeredByOptions.GO_TO_NEXT_AVAILABLE_DATE_LINK,
              )
            : Promise.resolve(),
        ]);
      } else {
        const shouldFetchDateAvailability = isMonthDifferent(
          selectedDate!,
          nextAvailableLocalDateTime,
        );

        await Promise.all([
          setSelectedDate(
            nextAvailableLocalDateTime,
            TriggeredByOptions.GO_TO_NEXT_AVAILABLE_DATE_LINK,
          ),
          shouldFetchDateAvailability
            ? setSelectedMonth(
                nextAvailableLocalDateTime,
                TriggeredByOptions.GO_TO_NEXT_AVAILABLE_DATE_LINK,
              )
            : Promise.resolve(),
        ]);
      }

      void biLogger.report(
        bookingsCalendarClick({
          component: WidgetComponents.TIME_PICKER,
          element: WidgetElements.GO_TO_NEXT_AVAILABLE_DATE_LINK,
          properties: JSON.stringify({
            selectedDate,
            nextAvailableDate: nextAvailableLocalDateTime,
          }),
        }),
      );

      autoSelectTime();
    } else {
      setState({
        slotsStatus: SlotsStatus.NO_AVAILABLE_SLOTS,
      });
      void biLogger.report(
        bookingsCalendarErrorMessages({
          errorMessage: CalendarErrors.NO_NEXT_AVAILABLE_DATE_WARNING,
        }),
      );
    }
  };
}
</calendarstate,>