import {
  QueryAvailabilityResponse,
  SlotAvailability,
} from '@wix/ambassador-bookings-availability-v1-slot-availability/types';
import { Optional } from '../../types/types';
import {
  isAllSlotsAreFull,
  isTooLateToBookAllSlots,
  isTooEarlyToBookAllSlots,
  isAtLeastOneSlotIsWithOpenWaitingList,
  isSlotStartsOnDate,
  isAllSlotsAreLocked,
} from '../slotAvailability/slotAvailability';

export interface TimeSlotAvailabilityStatus {
  allSlotsAreFull: boolean;
  tooLateToBookAllSlots: boolean;
  tooEarlyToBookAllSlots: boolean;
  withWaitingList: boolean;
  allSlotsAreLocked: boolean;
}

export const getTimeSlotsAvailabilityStatuses = (
  slotAvailabilities: SlotAvailability[] = [],
): Map<string, TimeSlotAvailabilityStatus=""> => {
  const timeSlotsAvailabilityStatus = new Map<
    string,
    TimeSlotAvailabilityStatus
  >();

  const compressedSlotsByRfcStartTime: Map<string, SlotAvailability[]=""> =
    compressSlotsByRfcStartTime(slotAvailabilities);
  compressedSlotsByRfcStartTime.forEach(
    (slots: SlotAvailability[], rfcStartTime: string) => {
      const timeSlotStatus = getTimeSlotAvailabilityStatus(slots);
      timeSlotsAvailabilityStatus.set(rfcStartTime, timeSlotStatus);
    },
  );

  return timeSlotsAvailabilityStatus;
};

export const getSlotAvailabilityStatuses = (
  slotAvailabilities: SlotAvailability[] = [],
): TimeSlotAvailabilityStatus[] =>
  slotAvailabilities.map((slot) => getTimeSlotAvailabilityStatus([slot]));

const compressSlotsByRfcStartTime = (
  slotAvailabilities: SlotAvailability[],
): Map<string, SlotAvailability[]=""> => {
  const slotsByRfcStartTime = new Map<string, SlotAvailability[]="">();
  slotAvailabilities?.forEach((slot: SlotAvailability) => {
    const rfcStartTime = slot?.slot?.startDate;
    if (rfcStartTime) {
      const currentValue = slotsByRfcStartTime.get(rfcStartTime) || [];
      slotsByRfcStartTime.set(rfcStartTime, [...currentValue, slot]);
    }
  });
  return slotsByRfcStartTime;
};

export const getTimeSlotAvailabilityStatus = (
  slots: SlotAvailability[],
): TimeSlotAvailabilityStatus => {
  const allSlotsAreFull = isAllSlotsAreFull(slots);
  const tooLateToBookAllSlots = isTooLateToBookAllSlots(slots);
  const tooEarlyToBookAllSlots = isTooEarlyToBookAllSlots(slots);
  const atLeastOneSlotIsWithWaitingList =
    isAtLeastOneSlotIsWithOpenWaitingList(slots);

  return {
    allSlotsAreFull: allSlotsAreFull && !atLeastOneSlotIsWithWaitingList,
    tooLateToBookAllSlots,
    tooEarlyToBookAllSlots,
    withWaitingList: allSlotsAreFull && atLeastOneSlotIsWithWaitingList,
    allSlotsAreLocked: isAllSlotsAreLocked(slots),
  };
};

export const filterTimeSlotsAvailabilityStatusesByDate = (
  timeSlotsAvailabilityStatuses: Map<string, TimeSlotAvailabilityStatus="">,
  dateAsLocalDate: string,
): Map<string, TimeSlotAvailabilityStatus=""> => {
  const timeSlotAvailabilityStatusesInGivenDate = new Map<
    string,
    TimeSlotAvailabilityStatus
  >();
  timeSlotsAvailabilityStatuses.forEach(
    (timeSlotStatus: TimeSlotAvailabilityStatus, rfcStartTime: string) => {
      const slotStartsOnDate = isSlotStartsOnDate({
        rfcStartTime,
        dateAsLocalDate,
      });
      if (slotStartsOnDate) {
        timeSlotAvailabilityStatusesInGivenDate.set(
          rfcStartTime,
          timeSlotStatus,
        );
      }
    },
  );
  return timeSlotAvailabilityStatusesInGivenDate;
};

export const getNumberOfAvailableTimeSlots = (
  timeSlotsAvailabilityStatuses: Map<string, TimeSlotAvailabilityStatus="">,
): number => {
  let numberOfAvailableTimeSlots = 0;
  timeSlotsAvailabilityStatuses.forEach(
    (status: TimeSlotAvailabilityStatus) => {
      if (
        !status.tooLateToBookAllSlots &&
        !status.tooEarlyToBookAllSlots &&
        !status.allSlotsAreFull
      ) {
        numberOfAvailableTimeSlots++;
      }
    },
  );
  return numberOfAvailableTimeSlots;
};

export const isSelectedTimeSlotWithWaitingListIndication = (
  availableSlots: Optional<queryavailabilityresponse>,
  selectedTime: string,
) => {
  const slotsAtSelectedTime =
    availableSlots?.availabilityEntries!.filter(
      (slot) => slot.slot?.startDate === selectedTime,
    ) || [];

  return (
    isAllSlotsAreFull(slotsAtSelectedTime!) &&
    isAtLeastOneSlotIsWithOpenWaitingList(slotsAtSelectedTime!)
  );
};
</queryavailabilityresponse></string,></string,></string,></string,></string,></string,></string,>