import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { SetSelectedMonth } from '../setSelectedMonth/setSelectedMonth';
import { WidgetComponents, WidgetElements } from '../../../../utils/bi/consts';
import { TriggeredByOptions } from '../../../../types/types';
import { bookingsCalendarClick } from '@wix/bi-logger-wixboost-ugc/v2';

export type OnMonthSelected = (localDateTime: string) => Promise<void>;

export function createOnMonthSelectedAction(
  {
    getControllerState,
    context: { biLogger },
  }: ActionFactoryParams<calendarstate, CalendarContext="">,
  setSelectedMonth: SetSelectedMonth,
): OnMonthSelected {
  return async (localDateTime: string) => {
    await setSelectedMonth(localDateTime, TriggeredByOptions.MONTH_SELECTED);
    biLogger.report(
      bookingsCalendarClick({
        component: WidgetComponents.DATE_PICKER,
        element: WidgetElements.MONTH_ARROW,
      }),
    );
  };
}
</calendarstate,></void>