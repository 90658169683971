import { CalendarContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { currentLanguage } from '../../../../utils/currentLanguage/currentLanguage';
import { CalendarState } from '../../controller';

export type OnLoginClick = () => void;

export function createOnLoginClickAction({
  context: {
    flowAPI: {
      environment,
      controllerConfig: { wixCodeApi },
    },
  },
}: ActionFactoryParams<calendarstate, CalendarContext="">): OnLoginClick {
  return () => {
    if (!environment.isPreview) {
      wixCodeApi.user.promptLogin({
        mode: 'login',
        lang: currentLanguage(wixCodeApi),
      });
    }
  };
}
</calendarstate,>